import Vue from 'vue'

import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';

Vue.use(Quasar, {
  plugins: {
    Notify,
  },
  config: {
    notify: {}
  }
 })